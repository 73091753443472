export 

const SingleprojectDetail = [
    {
      name: "ardete",
      youtubeLink: "https://www.youtube.com/shorts/Z_o_1Y3Cnpc?feature=share",
      videoLink: "https://www.youtube.com/shorts/IQTRbf0rZCk",
      instagramLink: "https://www.instagram.com/reel/C6x6Q5lSON8/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==",
      Projectname:"Studio Ardete HQ",
      metatittles:"Studio Ardete | Parametric Facade with MetaFin & MetaSequin",
      metadescription:"A dynamic parametric facade in Mohali, featuring MetaFin and shimmering MetaSequin panels by Metaguise.",
      
      images: [
  
        "assets/Allprojects/commercial/Ardete/metafin/ardete3.webp",
        "assets/Allprojects/commercial/Ardete/metafin/ardete4.webp",
        "assets/Allprojects/commercial/Ardete/metafin/ardete8.webp",
        "assets/Allprojects/commercial/Ardete/metafin/ardete14.webp",
        "assets/Allprojects/commercial/Ardete/metafin/ardete15.webp",
        "assets/Allprojects/commercial/Ardete/metafin/ardete16.webp",
        "assets/Allprojects/commercial/Ardete/metasequin/ardete1.webp",
        "assets/Allprojects/commercial/Ardete/metasequin/ardete2.webp",
        "assets/Allprojects/commercial/Ardete/metasequin/ardete6.webp",
        "assets/Allprojects/commercial/Ardete/metasequin/ardete9.webp",
        "assets/Allprojects/commercial/Ardete/metasequin/ardete10.webp",
        "assets/Allprojects/commercial/Ardete/metasequin/ardete17.webp",
        "assets/Allprojects/commercial/Ardete/metasequin/ardete18.webp",
        "assets/Allprojects/commercial/Ardete/metasequin/ardete19.webp",
        "assets/Allprojects/commercial/Ardete/night/ardete7.webp",
        "assets/Allprojects/commercial/Ardete/night/ardete12.webp",
        "assets/Allprojects/commercial/Ardete/night/ardete13.webp",
        "assets/Allprojects/commercial/Ardete/night/ardete20.webp",
      ],
    },
    {
      name: "tanishq",
      videoLink: "https://www.youtube.com/watch?v=nJSmApEceRE",
      youtubeLink: "https://www.youtube.com/watch?v=nJSmApEceRE",
      instagramLink: "https://www.instagram.com/p/C7yTAU0y66T/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA%3D%3D",
      Projectname:"Tanishq Jewellery",
      metatittles:"Tanishq Delhi | Parametric Coin Facade with MetaFlutes & MetaCassette",
      metadescription:"A signature parametric coin facade in gold tones complemneted by MetaCoin and MetaFlute for Tanishq Jewellers.",
      images: [
        "assets/Allprojects/commercial/Tanishq/metacoin/tanishq1.webp",
        "assets/Allprojects/commercial/Tanishq/metacoin/tanishq5.webp",
        "assets/Allprojects/commercial/Tanishq/metacoin/tanishq6.webp",
        "assets/Allprojects/commercial/Tanishq/metacoin/tanishq7.webp",
        "assets/Allprojects/commercial/Tanishq/metacoin/tanishq8.webp",
        "assets/Allprojects/commercial/Tanishq/metacoin/tanishq9.webp",
        "assets/Allprojects/commercial/Tanishq/metaflute/tanishq16.webp",
        "assets/Allprojects/commercial/Tanishq/metaflute/tanishq3.webp",
        "assets/Allprojects/commercial/Tanishq/metaflute/tanishq10.webp",
        "assets/Allprojects/commercial/Tanishq/metaflute/tanishq17.webp",
        "assets/Allprojects/commercial/Tanishq/night/tanishq2.webp",
        "assets/Allprojects/commercial/Tanishq/night/tanishq4.webp",
        "assets/Allprojects/commercial/Tanishq/night/tanishq12.webp",
        "assets/Allprojects/commercial/Tanishq/night/tanishq13.webp",
        "assets/Allprojects/commercial/Tanishq/night/tanishq14.webp",
        "assets/Allprojects/commercial/Tanishq/night/tanishq15.webp",
      ],
    },

    //Luxe
    {
      name: "Luxe",
      videoLink: "https://www.youtube.com/shorts/rMUBnzQeJps",
      youtubeLink: "https://www.youtube.com/shorts/6Wk0hLXPGPo?feature=share",
      instagramLink: "https://www.instagram.com/reel/DDeZaTDyLPS/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==",
      Projectname:"Luxe Manor",
      metatittles:"Luxe Mansor, New Delhi | Iconic Parametric Facade with MetaCoin & MetaFlute",
      metadescription:"Metaguise designed a striking facade for Tanishq, featuring uniquely shaped parametric MetaCoin and wooden MetaFlute panels, creating a shimmering, jewel-like effect.",
      images: [
        "assets/Allprojects/residential/Luxe/night/luxe1.webp",
        "assets/Allprojects/residential/Luxe/night/luxe2.webp",
        "assets/Allprojects/residential/Luxe/night/luxe9.webp",
        "assets/Allprojects/residential/Luxe/night/luxe11.webp",
        "assets/Allprojects/residential/Luxe/night/luxe14.webp",
        "assets/Allprojects/residential/Luxe/night/luxe15.webp",
        "assets/Allprojects/residential/Luxe/metasequin/luxe3.webp",
        "assets/Allprojects/residential/Luxe/metasequin/luxe5.webp",
        "assets/Allprojects/residential/Luxe/metasequin/luxe7.webp",
        "assets/Allprojects/residential/Luxe/metaflute/luxe6.webp",
        "assets/Allprojects/residential/Luxe/metaflute/luxe13.webp",
        "assets/Allprojects/residential/Luxe/metafin/luxe4.webp",
        "assets/Allprojects/residential/Luxe/metafin/luxe8.webp",
        "assets/Allprojects/residential/Luxe/metafin/luxe10.webp",
        "assets/Allprojects/residential/Luxe/metafin/luxe12.webp",
      ],
    },
    {
      name: "A13-43",
      videoLink: "https://www.youtube.com/watch?v=bsZgLDDyA3A",
      youtubeLink: "https://www.youtube.com/watch?v=bsZgLDDyA3A",
      instagramLink: "https://www.instagram.com/p/CyTO-QXyZGj/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==",
      Projectname:"A13-43",
      metatittles:"Modern Home | Metal Facade with MetaPyramid & MetaLouver",
      metadescription:"This residence features metal facade detailing with MetaPyramids, MetaLouvers, and linear MetaPlank panels.",
      images: [
        "assets/Allprojects/residential/A13-43/metalouver/a13-433.webp",
        "assets/Allprojects/residential/A13-43/metalouver/a13-437.webp",
        "assets/Allprojects/residential/A13-43/metalouver/a13-4316.webp",
        "assets/Allprojects/residential/A13-43/metaplank/a13-434.webp",
        "assets/Allprojects/residential/A13-43/metaplank/a13-438.webp",
        "assets/Allprojects/residential/A13-43/metaplank/a13-4310.webp",
        "assets/Allprojects/residential/A13-43/metaplank/a13-4312.webp",
        "assets/Allprojects/residential/A13-43/metaplank/a13-4317.webp",
        "assets/Allprojects/residential/A13-43/metaplank/a13-4318.webp",
        "assets/Allprojects/residential/A13-43/metaplank/a13-4319.webp",
        "assets/Allprojects/residential/A13-43/metapyramid/a13-432.webp",
        "assets/Allprojects/residential/A13-43/metapyramid/a13-435.webp",
        "assets/Allprojects/residential/A13-43/metapyramid/a13-436.webp",
        "assets/Allprojects/residential/A13-43/metapyramid/a13-439.webp",
        "assets/Allprojects/residential/A13-43/metapyramid/a13-4311.webp",
        "assets/Allprojects/residential/A13-43/night/a13-4313.webp",
        "assets/Allprojects/residential/A13-43/night/a13-4314.webp",
        "assets/Allprojects/residential/A13-43/night/a13-4315.webp",
      ],
    },
    {
      name: "D5-3",
      videoLink: "https://www.youtube.com/watch?v=hFQKyKcM1G8",
      youtubeLink: "https://www.youtube.com/shorts/gE5wc_E-6Jg?feature=share",
      instagramLink: "https://www.instagram.com/p/DADNgGVyMxC/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==",
      Projectname:"D5-3",
      metatittles:"Contemporary Home | Facade Cladding with MetaFold & MetaLouvers",
      metadescription:"A seamless facade cladding mix of MetaFold, MetaCassette, and MetaLouver for layered elevation design.",
      images: [
        "assets/Allprojects/residential/D5-3/metacassette-grooved/ankit10.webp",
        "assets/Allprojects/residential/D5-3/metacassette-grooved/ankit11.webp",
        "assets/Allprojects/residential/D5-3/metacassette-grooved/ankit12.webp",
        "assets/Allprojects/residential/D5-3/metacassette-grooved/ankit13.webp",
        "assets/Allprojects/residential/D5-3/metaflute/ankit6.webp",
        "assets/Allprojects/residential/D5-3/metaflute/ankit9.webp",
        "assets/Allprojects/residential/D5-3/metaflute/ankit14.webp",
        "assets/Allprojects/residential/D5-3/metaflute/ankit15.webp",
        "assets/Allprojects/residential/D5-3/metafold/ankit4.webp",
        "assets/Allprojects/residential/D5-3/metafold/ankit5.webp",
        "assets/Allprojects/residential/D5-3/metafold/ankit7.webp",
        "assets/Allprojects/residential/D5-3/metafold/ankit8.webp",
        "assets/Allprojects/residential/D5-3/metalouver/ankit16.webp",
        "assets/Allprojects/residential/D5-3/metalouver/ankit2.webp",
        "assets/Allprojects/residential/D5-3/metalouver/ankit3.webp",
        "assets/Allprojects/residential/D5-3/metalouver/ankit17.webp",
      ],
    },
    {
      name: "gowri",
      videoLink: "https://www.youtube.com/shorts/KzYLNPaMkB8",
      youtubeLink: "https://www.youtube.com/shorts/KzYLNPaMkB8?feature=share",
      instagramLink: "https://www.instagram.com/reel/C5VhWpTyXzx/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==",
      Projectname:"Gowri Jewelers",
      metatittles:"Gowri’s Jewellery | Metal Sequin Facade Crafted by Metaguise",
      metadescription:"A bespoke metal sequin facade designed for visual movement and light interaction.",
      images: [
        "assets/Allprojects/commercial/Gowri/metasequin/gowri1.webp",
        "assets/Allprojects/commercial/Gowri/metasequin/gowri3.webp",
        "assets/Allprojects/commercial/Gowri/metasequin/gowri4.webp",
        "assets/Allprojects/commercial/Gowri/metasequin/gowri5.webp",
        "assets/Allprojects/commercial/Gowri/metasequin/gowri6.webp",
        "assets/Allprojects/commercial/Gowri/metasequin/gowri7.webp",
        "assets/Allprojects/commercial/Gowri/metasequin/gowri8.webp",
        "assets/Allprojects/commercial/Gowri/metasequin/gowri9.webp",
      ],
    },
    {
      name: "E10",
      videoLink: "https://www.youtube.com/shorts/L1BRTrBobAI",
      youtubeLink: "https://www.youtube.com/shorts/L1BRTrBobAI",
      instagramLink: "https://www.instagram.com/p/C0oeC5NSFp0/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==",
      Projectname:"E10-HK",
      metatittles:"Commercial Architecture | Parametric Facade with MetaFin",
      metadescription:"A fluid parametric facade using MetaFin’s seamless curves for a bold and organic commercial presence.",
      images: [
        "assets/Allprojects/commercial/E10/metafin/e101.webp",
        "assets/Allprojects/commercial/E10/metafin/e103.webp",
        "assets/Allprojects/commercial/E10/metafin/e107.webp",
        "assets/Allprojects/commercial/E10/metafin/e1010.webp",
        "assets/Allprojects/commercial/E10/metafin/e1011.webp",
        "assets/Allprojects/commercial/E10/night/e102.webp",
        "assets/Allprojects/commercial/E10/night/e104.webp",
        "assets/Allprojects/commercial/E10/night/e106.webp",
        "assets/Allprojects/commercial/E10/night/e108.webp",
        "assets/Allprojects/commercial/E10/night/e109.webp",
        "assets/Allprojects/commercial/E10/night/e1012.webp",
      ],
    },
    {
      name: "deepak",
      videoLink: "https://www.youtube.com/watch?v=dAarntEa15o",
      youtubeLink: "https://www.youtube.com/watch?v=dAarntEa15o",
      instagramLink: "https://www.instagram.com/p/C8bd64HyWOX/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==",
      Projectname:"Deepak Sweets",
      metatittles:"Deepak Sweets | Metal Facade with MetaSequin & MetaCassette ",
      metadescription:"This vibrant store front in Bareilly showcases a metal facade cladding with MetaSequin and MetaCassette.",
      images: [
        "assets/Allprojects/commercial/Deepak/metacassette-perforated/deepak6.webp",
        "assets/Allprojects/commercial/Deepak/metacassette-perforated/deepak13.webp",
        "assets/Allprojects/commercial/Deepak/metacassette-perforated/deepak16.webp",
        "assets/Allprojects/commercial/Deepak/metacassette-perforated/deepak20.webp",
        "assets/Allprojects/commercial/Deepak/metacassette-perforated/deepak21.webp",
        "assets/Allprojects/commercial/Deepak/metapyramid/deepak3.webp",
        "assets/Allprojects/commercial/Deepak/metapyramid/deepak5.webp",
        "assets/Allprojects/commercial/Deepak/metapyramid/deepak8.webp",
        "assets/Allprojects/commercial/Deepak/metapyramid/deepak15.webp",
        "assets/Allprojects/commercial/Deepak/metapyramid/deepak18.webp",
        "assets/Allprojects/commercial/Deepak/metapyramid/deepak19.webp",
        "assets/Allprojects/commercial/Deepak/metasequin/deepak17.webp",
        "assets/Allprojects/commercial/Deepak/metasequin/deepak2.webp",
        "assets/Allprojects/commercial/Deepak/metasequin/deepak11.webp",
        "assets/Allprojects/commercial/Deepak/metasequin/deepak22.webp",
        "assets/Allprojects/commercial/Deepak/metasequin/deepak14.webp",
        "assets/Allprojects/commercial/Deepak/metasequin/deepak23.webp",
        "assets/Allprojects/commercial/Deepak/night/deepak4.webp",
        "assets/Allprojects/commercial/Deepak/night/deepak7.webp",
        "assets/Allprojects/commercial/Deepak/night/deepak9.webp",
        "assets/Allprojects/commercial/Deepak/night/deepak10.webp",
        "assets/Allprojects/commercial/Deepak/night/deepak12.webp",
      ],
    },
    {
      name: "A19",
      videoLink: "https://www.youtube.com/shorts/EerdVK4Ax9M?feature=share",
      youtubeLink: "https://www.youtube.com/shorts/EerdVK4Ax9M?feature=share",
      instagramLink: "https://www.instagram.com/p/DBQQFHRyB7-/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==",
      Projectname:"A19-DL1",
      metatittles:"Modern Home | Facade Panels with MetaCassette & MetaLouver",
      metadescription:"A fresh residential elevation using MetaLouvers and MetaCassette facade panels for modern rhythm and ventilation.",
      images: [
        "assets/Allprojects/residential/A19/metacassette-grooved/a1910.webp",
        "assets/Allprojects/residential/A19/metacassette-grooved/a1911.webp",
        "assets/Allprojects/residential/A19/metacassette-grooved/a1912.webp",
        "assets/Allprojects/residential/A19/metacassette-grooved/a1913.webp",
        "assets/Allprojects/residential/A19/metacassette-perforated/a199.webp",
        "assets/Allprojects/residential/A19/metacassette-perforated/a1914.webp",
        "assets/Allprojects/residential/A19/metacassette-perforated/a1915.webp",
        "assets/Allprojects/residential/A19/metacassette-perforated/a1917.webp",
        "assets/Allprojects/residential/A19/metaflute/a191.webp",
        "assets/Allprojects/residential/A19/metaflute/a193.webp",
        "assets/Allprojects/residential/A19/metaflute/a195.webp",
        "assets/Allprojects/residential/A19/metaflute/a196.webp",
        "assets/Allprojects/residential/A19/metalouver/a194.webp",
        "assets/Allprojects/residential/A19/metalouver/a197.webp",
        "assets/Allprojects/residential/A19/metalouver/a198.webp",
        "assets/Allprojects/residential/A19/metalouver/a1916.webp",
      ],
    },
    {
      name: "A18",
      videoLink: "https://www.youtube.com/watch?v=-K2JTghXJTw",
      youtubeLink: "https://www.youtube.com/watch?v=-K2JTghXJTw",
      instagramLink: "https://www.instagram.com/reel/Cy6EXmmSizr/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==",
      Projectname:"A18-DL1",
      metatittles:"Elegant Elevation | Metal Facade with MetaFlute & MetaLouvers",
      metadescription:"This refined home uses our high value metal facade materials with fluted and louvered profiles for contrast and depth.",
      images: [
        "assets/Allprojects/residential/A18/metacassette-perforated/a182.webp",
        "assets/Allprojects/residential/A18/metacassette-perforated/a184.webp",
        "assets/Allprojects/residential/A18/metacassette-perforated/a185.webp",
        "assets/Allprojects/residential/A18/metacassette-perforated/a186.webp",
        "assets/Allprojects/residential/A18/metacassette-perforated/a188.webp",
        "assets/Allprojects/residential/A18/metacassette-perforated/a1812.webp",
        "assets/Allprojects/residential/A18/metacassette-perforated/a1817.webp",
        "assets/Allprojects/residential/A18/metacassette-perforated/a1819.webp",
        "assets/Allprojects/residential/A18/metaflute/a189.webp",
        "assets/Allprojects/residential/A18/metaflute/a1813.webp",
        "assets/Allprojects/residential/A18/metaflute/a1814.webp",
        "assets/Allprojects/residential/A18/metaflute/a1815.webp",
        "assets/Allprojects/residential/A18/metaflute/a1816.webp",
        "assets/Allprojects/residential/A18/metalouver/a183.webp",
        "assets/Allprojects/residential/A18/metalouver/a1810.webp",
        "assets/Allprojects/residential/A18/metalouver/a1811.webp",
        "assets/Allprojects/residential/A18/metalouver/a1818.webp",
      ],
    },
    {
      name: "MVA-DL1",
      videoLink: "https://www.youtube.com/watch?v=N5lCQ8fSvgA",
      youtubeLink: "https://www.youtube.com/watch?v=N5lCQ8fSvgA",
      instagramLink: "https://www.instagram.com/p/C92IFsGSdqx/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==",
      Projectname:"MVA-DL1",
      metatittles:"Modern Home | Metal Facade Gate with MetaCassette",
      metadescription:"A strong architectural expression with metal facade cladding using MetaCassette grooved panels and integrated MetaGate entrance.",
      images: [
        "assets/Allprojects/residential/MVA-DL1/metacassette-grooved/varun2.webp",
        "assets/Allprojects/residential/MVA-DL1/metacassette-grooved/varun3.webp",
        "assets/Allprojects/residential/MVA-DL1/metacassette-grooved/varun7.webp",
        "assets/Allprojects/residential/MVA-DL1/metacassette-grooved/varun8.webp",
        "assets/Allprojects/residential/MVA-DL1/metacassette-grooved/varun9.webp",
        "assets/Allprojects/residential/MVA-DL1/metacassette-grooved/varun11.webp",
        "assets/Allprojects/residential/MVA-DL1/metacassette-grooved/varun13.webp",
        "assets/Allprojects/residential/MVA-DL1/metagate/varun10.webp",
        "assets/Allprojects/residential/MVA-DL1/metagate/varun12.webp",
        "assets/Allprojects/residential/MVA-DL1/metagate/varun14.webp",
        "assets/Allprojects/residential/MVA-DL1/metagate/varun16.webp",
        "assets/Allprojects/residential/MVA-DL1/night/varun1.webp",
        "assets/Allprojects/residential/MVA-DL1/night/varun4.webp",
        "assets/Allprojects/residential/MVA-DL1/night/varun6.webp",
        "assets/Allprojects/residential/MVA-DL1/night/varun15.webp",
      ],
    },
    {
      name: "metaland",
      videoLink: "https://www.youtube.com/watch?v=mwmFELxs14E",
      youtubeLink: "https://www.youtube.com/watch?v=mwmFELxs14E",
      instagramLink: "https://www.instagram.com/reel/C6f5mGtSajX/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==",
      Projectname:"Metaland",
      metatittles:" METALAND™ | Parametric Sequin Facade by Metaguise",
      metadescription:"Our flagship experience center with bespoke shimmering parametric metal sequin panels.",
      images: [
        "assets/Allprojects/commercial/Metaland/metasequin/metaland14.webp",
        "assets/Allprojects/commercial/Metaland/metasequin/metaland8.webp",
        "assets/Allprojects/commercial/Metaland/metasequin/metaland9.webp",
        "assets/Allprojects/commercial/Metaland/metasequin/metaland10.webp",
        "assets/Allprojects/commercial/Metaland/metasequin/metaland11.webp",
        "assets/Allprojects/commercial/Metaland/night/metaland1.webp",
        "assets/Allprojects/commercial/Metaland/night/metaland2.webp",
        "assets/Allprojects/commercial/Metaland/night/metaland3.webp",
        "assets/Allprojects/commercial/Metaland/night/metaland4.webp",
        "assets/Allprojects/commercial/Metaland/night/metaland5.webp",
        "assets/Allprojects/commercial/Metaland/night/metaland7.webp",
        "assets/Allprojects/commercial/Metaland/night/metaland12.webp",
        "assets/Allprojects/commercial/Metaland/night/metaland13.webp",
      ],
    },
    {
      name: "micasa",
      videoLink: "https://www.youtube.com/watch?v=d_0Ie4xGsKQ&feature=youtu.be",
      youtubeLink: "https://www.youtube.com/watch?v=d_0Ie4xGsKQ&feature=youtu.be",
      instagramLink: "https://www.instagram.com/p/DAhz8F2S2Sf/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==",
      Projectname:"Micasa",
      metatittles:"Micasa Delhi | A Bold Parametric Coin Facade ",
      metadescription:"Commercial building with a striking gold parametric coin matrix facade featuring a wave like pattern.",
      images: [
        "assets/Allprojects/commercial/Micasa/metacoin/micasa2.webp",
        "assets/Allprojects/commercial/Micasa/metacoin/micasa3.webp",
        "assets/Allprojects/commercial/Micasa/metacoin/micasa4.webp",
        "assets/Allprojects/commercial/Micasa/metacoin/micasa5.webp",
        "assets/Allprojects/commercial/Micasa/metacoin/micasa6.webp",
        "assets/Allprojects/commercial/Micasa/metacoin/micasa7.webp",
        "assets/Allprojects/commercial/Micasa/metacoin/micasa8.webp",
        "assets/Allprojects/commercial/Micasa/metacoin/micasa9.webp",
      ],
    },
    {
      name: "Yashika",
      videoLink: "https://www.youtube.com/shorts/PjSB1brakw0?feature=share",
      youtubeLink: "https://www.youtube.com/shorts/PjSB1brakw0?feature=share",
      instagramLink: "https://www.instagram.com/p/C3Uxk3VSyQO/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==",
      Projectname:"Yashika",
      metatittles:"Bold Commercial | Parametric Facade with MetaCoin",
      metadescription:"A bold statement in commercial architecture using parametric facade technology with MetaCoin.",
      images: [
        "assets/Allprojects/commercial/yashika/metacoin/yashika2.webp",
        "assets/Allprojects/commercial/yashika/metacoin/yashika3.webp",
        "assets/Allprojects/commercial/yashika/metacoin/yashika4.webp",
        "assets/Allprojects/commercial/yashika/metacoin/yashika5.webp",
        "assets/Allprojects/commercial/yashika/metacoin/yashika6.webp",
        "assets/Allprojects/commercial/yashika/metacoin/yashika7.webp",
        "assets/Allprojects/commercial/yashika/metacoin/yashika8.webp",
        "assets/Allprojects/commercial/yashika/metacoin/yashika9.webp",
        "assets/Allprojects/commercial/yashika/metacoin/yashika10.webp",
      ],
    },
    {
      name: "C76",
      videoLink: "https://www.youtube.com/watch?v=r6kNjqo6PgQ",
      youtubeLink: "https://www.youtube.com/watch?v=r6kNjqo6PgQ",
      instagramLink: "https://www.instagram.com/reel/C5lPK9QSXHU/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==",
      Projectname:"C76",
      metatittles:"Grooved Facade | Metal Facade Panels with MetaCassette & MetaFlute",
      metadescription:"This residence features Metacassette grooved metal facade panels with vertical fluting for a modern look.",
      images: [
        "assets/Allprojects/residential/C76/metacassette-grooved/c769.webp",
        "assets/Allprojects/residential/C76/metacassette-grooved/c7612.webp",
        "assets/Allprojects/residential/C76/metacassette-grooved/c7614.webp",
        "assets/Allprojects/residential/C76/metacassette-grooved/c7619.webp",
        "assets/Allprojects/residential/C76/metaflute/c762.webp",
        "assets/Allprojects/residential/C76/metaflute/c763.webp",
        "assets/Allprojects/residential/C76/metaflute/c768.webp",
        "assets/Allprojects/residential/C76/metaflute/c7611.webp",
        "assets/Allprojects/residential/C76/metaflute/c7613.webp",
        "assets/Allprojects/residential/C76/metaflute/c7617.webp",
        "assets/Allprojects/residential/C76/metaflute/c7618.webp",
        "assets/Allprojects/residential/C76/night/c761.webp",
        "assets/Allprojects/residential/C76/night/c764.webp",
        "assets/Allprojects/residential/C76/night/c765.webp",
        "assets/Allprojects/residential/C76/night/c767.webp",
      ],
    },
    {
      name: "5107",
      videoLink: "https://www.youtube.com/shorts/oOiyN07PwQE?feature=share",
      youtubeLink: "https://www.youtube.com/shorts/oOiyN07PwQE?feature=share",
      instagramLink: "https://www.instagram.com/p/C0d5yYYS90q/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==",
      Projectname:"5107",
      metatittles:"Modern Residential Design | Perforated Facade Panels ",
      metadescription:"Perforated facade panels in MetaCassette allow light, shadow, and texture to define this modern home.",
      images: [
        "assets/Allprojects/residential/5107/metacassette-perforated/51072.webp",
        "assets/Allprojects/residential/5107/metacassette-perforated/51073.webp",
        "assets/Allprojects/residential/5107/metacassette-perforated/51074.webp",
        "assets/Allprojects/residential/5107/metacassette-perforated/51078.webp",
        "assets/Allprojects/residential/5107/metacassette-perforated/510710.webp",
        "assets/Allprojects/residential/5107/metacassette-perforated/510711.webp",
        "assets/Allprojects/residential/5107/night/51074.webp",
        "assets/Allprojects/residential/5107/night/51075.webp",
        "assets/Allprojects/residential/5107/night/51077.webp",
        "assets/Allprojects/residential/5107/night/51079.webp",
        "assets/Allprojects/residential/5107/night/510712.webp",
        "assets/Allprojects/residential/5107/night/510713.webp",
        "assets/Allprojects/residential/5107/night/510714.webp",
      ],
    },
    {
      name: "A297",
      videoLink: "https://www.youtube.com/watch?v=_ei1jkRmp_Q",
      youtubeLink: "https://www.youtube.com/watch?v=_ei1jkRmp_Q",
      instagramLink: "https://www.instagram.com/p/C3KeYlvyFdK/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==",
      Projectname:"A297",
      metatittles:"Modern Facade | MetaFlutes, MetaShingles and Metal Louver Facade",
      metadescription:"A layered metal facade design combining MetaShingle, MetaFlute, and functional MetaLouver panels.",
      images: [
        "assets/Allprojects/residential/A297/metaflute/a2973.webp",
        "assets/Allprojects/residential/A297/metaflute/a2975.webp",
        "assets/Allprojects/residential/A297/metaflute/a2976.webp",
        "assets/Allprojects/residential/A297/metaflute/a2979.webp",
        "assets/Allprojects/residential/A297/metaflute/a29713.webp",
        "assets/Allprojects/residential/A297/metagate/a2974.webp",
        "assets/Allprojects/residential/A297/metagate/a29715.webp",
        "assets/Allprojects/residential/A297/metagate/a29716.webp",
        "assets/Allprojects/residential/A297/metalouver/a2977.webp",
        "assets/Allprojects/residential/A297/metalouver/a29712.webp",
        "assets/Allprojects/residential/A297/metalouver/a29714.webp",
        "assets/Allprojects/residential/A297/metalouver/a29717.webp",
        "assets/Allprojects/residential/A297/metashingle/a2972.webp",
        "assets/Allprojects/residential/A297/metashingle/a2978.webp",
        "assets/Allprojects/residential/A297/metashingle/a29710.webp",
        "assets/Allprojects/residential/A297/metashingle/a29711.webp",
      ],
    },
    // {
    //   name: "linearretreat",
    //   images: [
    //     "assets/Allprojects/residential/linearretreat/metaflute/linearretreat2.webp",
    //     "assets/Allprojects/residential/linearretreat/metaflute/linearretreat3.webp",
    //     "assets/Allprojects/residential/linearretreat/metaflute/linearretreat4.webp",
    //     "assets/Allprojects/residential/linearretreat/metaflute/linearretreat5.webp",
    //     "assets/Allprojects/residential/linearretreat/metaflute/linearretreat6.webp",
    //     "assets/Allprojects/residential/linearretreat/metaflute/linearretreat7.webp",
    //     "assets/Allprojects/residential/linearretreat/metaflute/linearretreat8.webp",
    //     "assets/Allprojects/residential/linearretreat/metaflute/linearretreat9.webp",
    //   ],
    // },
    {
      name: "C34-573",
      videoLink: "https://www.youtube.com/watch?v=O99SARUeRa0",
      youtubeLink: "https://www.youtube.com/watch?v=O99SARUeRa0",
      instagramLink: "https://www.instagram.com/p/C8wEVfgSrCF/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==",
      Projectname:"C3/4-S73",
      metatittles:"Residential Elevation | Parametric Facade with MetaCoin & MetaShingle",
      metadescription:"A dynamic parametric facade combining MetaCoin with overlapping MetaShingles.",
      images: [
        "assets/Allprojects/residential/C34-573/metacoin/kapil2.webp",
        "assets/Allprojects/residential/C34-573/metacoin/kapil3.webp",
        "assets/Allprojects/residential/C34-573/metacoin/kapil4.webp",
        "assets/Allprojects/residential/C34-573/metacoin/kapil6.webp",
        "assets/Allprojects/residential/C34-573/metacoin/kapil8.webp",
        "assets/Allprojects/residential/C34-573/metacoin/kapil9.webp",
        "assets/Allprojects/residential/C34-573/metacoin/kapil10.webp",
        "assets/Allprojects/residential/C34-573/metashingle/kapil11.webp",
        "assets/Allprojects/residential/C34-573/metashingle/kapil5.webp",
        "assets/Allprojects/residential/C34-573/metashingle/kapil7.webp",
        "assets/Allprojects/residential/C34-573/metashingle/kapil12.webp",
      ],
    },
    {
      name: "DLF4",
      videoLink: "https://www.youtube.com/watch?v=gi0qDnaOmfI",
      youtubeLink: "https://www.youtube.com/watch?v=gi0qDnaOmfI",
      instagramLink: "https://www.instagram.com/p/C2uQpm1y7JB/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==",
      Projectname:"DLF4",
      metatittles:"Modern Home | Metal Facade with Flutes & Solid Panels",
      metadescription:"A sleek metal facade elevation using MetaFlute and solid panels for linear definition and form.",
      images: [
        "assets/Allprojects/residential/DLF4/metaflute/dlf15.webp",
        "assets/Allprojects/residential/DLF4/metaflute/dlf16.webp",
        "assets/Allprojects/residential/DLF4/metaflute/dlf17.webp",
        "assets/Allprojects/residential/DLF4/metaflute/dlf48.webp",
        "assets/Allprojects/residential/DLF4/night/dlf42.webp",
        "assets/Allprojects/residential/DLF4/night/dlf43.webp",
        "assets/Allprojects/residential/DLF4/night/dlf45.webp",
        "assets/Allprojects/residential/DLF4/night/dlf46.webp",
        "assets/Allprojects/residential/DLF4/night/dlf47.webp",
        "assets/Allprojects/residential/DLF4/night/dlf49.webp",
        "assets/Allprojects/residential/DLF4/night/dlf412.webp",
        "assets/Allprojects/residential/DLF4/solid-panel/dlf14.webp",
        "assets/Allprojects/residential/DLF4/solid-panel/dlf410.webp",
        "assets/Allprojects/residential/DLF4/solid-panel/dlf411.webp",
        "assets/Allprojects/residential/DLF4/solid-panel/dlf413.webp",
      ],
    },
    {
      name: "CS5-MA",
      videoLink: "https://www.youtube.com/watch?v=6RoELQ5I-1g",
      youtubeLink: "https://www.youtube.com/watch?v=6RoELQ5I-1g",
      instagramLink: "https://www.instagram.com/p/CyDrwYkSG7e/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==",
      Projectname:"CS5-MA",
      metatittles:"A sleek metal facade elevation using MetaFlute and solid panels for linear definition and form.",
      metadescription:"This facade blends perforated MetaCassette facade panels and metal flutes and louvers for elevation.",
      images: [
        "assets/Allprojects/residential/CS5-MA/metacassette-perforated/vishal2.webp",
        "assets/Allprojects/residential/CS5-MA/metacassette-perforated/vishal9.webp",
        "assets/Allprojects/residential/CS5-MA/metacassette-perforated/vishal13.webp",
        "assets/Allprojects/residential/CS5-MA/metacassette-perforated/vishal14.webp",
        "assets/Allprojects/residential/CS5-MA/metaflute/vishal3.webp",
        "assets/Allprojects/residential/CS5-MA/metaflute/vishal4.webp",
        "assets/Allprojects/residential/CS5-MA/metaflute/vishal5.webp",
        "assets/Allprojects/residential/CS5-MA/metaflute/vishal6.webp",
        "assets/Allprojects/residential/CS5-MA/metaflute/vishal8.webp",
        "assets/Allprojects/residential/CS5-MA/metaflute/vishal11.webp",
        "assets/Allprojects/residential/CS5-MA/metalouver/vishal7.webp",
        "assets/Allprojects/residential/CS5-MA/metalouver/vishal21.webp",
        "assets/Allprojects/residential/CS5-MA/metalouver/vishal22.webp",
        "assets/Allprojects/residential/CS5-MA/metalouver/vishal23.webp",
        "assets/Allprojects/residential/CS5-MA/night/vishal10.webp",
        "assets/Allprojects/residential/CS5-MA/night/vishal12.webp",
        "assets/Allprojects/residential/CS5-MA/night/vishal20.webp",
        "assets/Allprojects/residential/CS5-MA/night/vishal24.webp",
      ],
    },
    {
      name: "espace",
      videoLink: "https://www.youtube.com/watch?v=0Or6FQP59x4",
      youtubeLink: "https://www.youtube.com/watch?v=0Or6FQP59x4",
      instagramLink: "https://www.instagram.com/p/C4NN0B3yv82/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==",
      Projectname:"Espace Nirvana",
      metatittles:"Residential Facade | MetaCassette, Plank & Metal Louver Facade",
      metadescription:"A refined facade cladding approach with MetaCassette, MetaPlank and MetaLouvers. ",
      images: [
        "assets/Allprojects/residential/Espace/metacassette-perforated/espace2.webp",
        "assets/Allprojects/residential/Espace/metacassette-perforated/espace5.webp",
        "assets/Allprojects/residential/Espace/metacassette-perforated/espace8.webp",
        "assets/Allprojects/residential/Espace/metacassette-perforated/espace12.webp",
        "assets/Allprojects/residential/Espace/metalouver-grey/espace3.webp",
        "assets/Allprojects/residential/Espace/metalouver-grey/espace6.webp",
        "assets/Allprojects/residential/Espace/metalouver-grey/espace9.webp",
        "assets/Allprojects/residential/Espace/metalouver-grey/espace15.webp",
        "assets/Allprojects/residential/Espace/metalouver-wood/espace17.webp",
        "assets/Allprojects/residential/Espace/metalouver-wood/espace4.webp",
        "assets/Allprojects/residential/Espace/metalouver-wood/espace14.webp",
        "assets/Allprojects/residential/Espace/metalouver-wood/espace16.webp",
        "assets/Allprojects/residential/Espace/metaplank-wood/espace7.webp",
        "assets/Allprojects/residential/Espace/metaplank-wood/espace18.webp",
        "assets/Allprojects/residential/Espace/metaplank-wood/espace19.webp",
        "assets/Allprojects/residential/Espace/metaplank-wood/espace20.webp",
      ],
    },
    {
      name: "MA-543",
      videoLink: "https://www.youtube.com/watch?v=Urze-UjHcLU",
      youtubeLink: "https://www.youtube.com/watch?v=Urze-UjHcLU",
      instagramLink: "https://www.instagram.com/p/C_CsfuZy_UM/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==",
      Projectname:"MA-543",
      metatittles:"Residential Design | Parametric Coin & Metal Louver Facade",
      metadescription:"A soft parametric coin matrix facade balanced with MetaLouvers and structured MetaPlank panels.",
      images: [
        "assets/Allprojects/residential/MA-543/metacoin/manoj8.webp",
        "assets/Allprojects/residential/MA-543/metacoin/manoj10.webp",
        "assets/Allprojects/residential/MA-543/metacoin/manoj11.webp",
        "assets/Allprojects/residential/MA-543/metacoin/manoj4.webp",
        "assets/Allprojects/residential/MA-543/metalouver/manoj2.webp",
        "assets/Allprojects/residential/MA-543/metalouver/manoj5.webp",
        "assets/Allprojects/residential/MA-543/metalouver/manoj7.webp",
        "assets/Allprojects/residential/MA-543/metalouver/manoj9.webp",
        "assets/Allprojects/residential/MA-543/metaplank/manoj3.webp",
        "assets/Allprojects/residential/MA-543/metaplank/manoj6.webp",
        "assets/Allprojects/residential/MA-543/metaplank/manoj12.webp",
        "assets/Allprojects/residential/MA-543/metaplank/manoj13.webp",
      ],
    },
    {
      name: "Q62-RA",
      videoLink: "https://www.youtube.com/watch?v=oEKOjT0tAWk",
      youtubeLink:"https://www.youtube.com/watch?v=oEKOjT0tAWk",
      instagramLink:"https://www.instagram.com/p/C_m5fBNhNuX/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA%3D%3D",
      Projectname:"Q62-RA",
      metatittles:"Modern Facade | MetaFlute & Metal Louver Facade",
      metadescription:"A bold look created with solid panels, fluted textures and metal louver facade.",
      images: [
        "assets/Allprojects/residential/Q62-RA/metaflute/tejbir4.webp",
        "assets/Allprojects/residential/Q62-RA/metaflute/tejbir6.webp",
        "assets/Allprojects/residential/Q62-RA/metaflute/tejbir7.webp",
        "assets/Allprojects/residential/Q62-RA/metaflute/tejbir14.webp",
        "assets/Allprojects/residential/Q62-RA/metalouver/tejbir2.webp",
        "assets/Allprojects/residential/Q62-RA/metalouver/tejbir3.webp",
        "assets/Allprojects/residential/Q62-RA/metalouver/tejbir8.webp",
        "assets/Allprojects/residential/Q62-RA/metalouver/tejbir11.webp",
        "assets/Allprojects/residential/Q62-RA/metalouver/tejbir13.webp",
        "assets/Allprojects/residential/Q62-RA/solid-panel/tejbir5.webp",
        "assets/Allprojects/residential/Q62-RA/solid-panel/tejbir9.webp",
        "assets/Allprojects/residential/Q62-RA/solid-panel/tejbir10.webp",
        "assets/Allprojects/residential/Q62-RA/solid-panel/tejbir12.webp",
      ],
    },
    {
      name: "z1012",
      videoLink: "https://www.youtube.com/watch?v=AwvlRroIAKA",
      youtubeLink: "https://www.youtube.com/watch?v=AwvlRroIAKA",
      instagramLink: "https://www.instagram.com/p/DA3Pqdey03I/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==",
      Projectname:"Z1012",
      metatittles:"Contemporary Metal Facade | Sleek MetaFlute & Overlapping MetaShingles",
      metadescription:"This elevation features a metal facade combining sleek MetaFlutes and layered MetaShingles",
      images: [
        "assets/Allprojects/residential/z1012/metaflute/z10122.webp",
        "assets/Allprojects/residential/z1012/metaflute/z10123.webp",
        "assets/Allprojects/residential/z1012/metaflute/z10128.webp",
        "assets/Allprojects/residential/z1012/metaflute/z101210.webp",
        "assets/Allprojects/residential/z1012/metaflute/z101211.webp",
        "assets/Allprojects/residential/z1012/metashingle/z10124.webp",
        "assets/Allprojects/residential/z1012/metashingle/z10125.webp",
        "assets/Allprojects/residential/z1012/metashingle/z10126.webp",
        "assets/Allprojects/residential/z1012/metashingle/z10127.webp",
        "assets/Allprojects/residential/z1012/metashingle/z10129.webp",
      ],
    },

    {
      name: "painflame",
      videoLink: "",
      youtubeLink: "https://www.youtube.com/watch?v=s6evsnaSFms",
      instagramLink: "https://www.instagram.com/p/DF68iI7Sslb/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA%3D%3D",
      Projectname:"Pain Flame",
      metatittles:"Parametric & Layered Facade for Healthcare Clinic ",
      metadescription:"Metaguise crafted a precision-engineered elevation for a Gurugram clinic, featuring the Parametric MetaCoin and Monochromatic MetaShingles for a dynamic, layered look.",
      images: [
        "assets/Allprojects/commercial/Painflame/metacoin/6.webp",
        "assets/Allprojects/commercial/Painflame/metacoin/8.webp",
        "assets/Allprojects/commercial/Painflame/metacoin/10.webp",
        "assets/Allprojects/commercial/Painflame/metacoin/14.webp",
        "assets/Allprojects/commercial/Painflame/metacoin/16.webp",
        "assets/Allprojects/commercial/Painflame/metacoin/18.webp",
        "assets/Allprojects/commercial/Painflame/metashingles/3.webp",
        "assets/Allprojects/commercial/Painflame/metashingles/11.webp",
        "assets/Allprojects/commercial/Painflame/metashingles/12.webp",
        "assets/Allprojects/commercial/Painflame/metashingles/17.webp",
        "assets/Allprojects/commercial/Painflame/night/1.webp",
        "assets/Allprojects/commercial/Painflame/night/9.webp",
        "assets/Allprojects/commercial/Painflame/night/13.webp",
       
      ],
    },

    {
      name: "premhospital",
      videoLink: "https://www.youtube.com/shorts/UV4ZyH_EFDc",
      youtubeLink: "https://www.youtube.com/watch?v=P4JgguI1nVg",
      instagramLink: "https://www.instagram.com/p/DDwba3FSzEo/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA%3D%3D",
      Projectname:"Prem Hospital",
      metatittles:"A Precision-Engineered Facade for a Hospital",
      metadescription:"Metaguise elevates healthcare architecture with MetaCassette Perforated and Grooved Panels, integrating logo-based perforations, and structured textures, in Gurugram",
      images: [
        "assets/Allprojects/commercial/PremHospital/metacassette-grooved/prem2.webp",
        "assets/Allprojects/commercial/PremHospital/metacassette-grooved/prem6.webp",
        "assets/Allprojects/commercial/PremHospital/metacassette-grooved/prem12.webp",
        "assets/Allprojects/commercial/PremHospital/metacassette-perforated/prem3.webp",
        "assets/Allprojects/commercial/PremHospital/metacassette-perforated/prem4.webp",
        "assets/Allprojects/commercial/PremHospital/metacassette-perforated/prem5.webp",
        "assets/Allprojects/commercial/PremHospital/metacassette-perforated/prem9.webp",
        "assets/Allprojects/commercial/PremHospital/metacassette-perforated/prem10.webp",
        "assets/Allprojects/commercial/PremHospital/metacassette-perforated/prem14.webp",
        "assets/Allprojects/commercial/PremHospital/metacassette-perforated/prem16.webp",
        "assets/Allprojects/commercial/PremHospital/night/prem7.webp",
        "assets/Allprojects/commercial/PremHospital/night/prem8.webp",
        "assets/Allprojects/commercial/PremHospital/night/prem11.webp",
        "assets/Allprojects/commercial/PremHospital/night/prem13.webp",
        "assets/Allprojects/commercial/PremHospital/night/prem15.webp",
        "assets/Allprojects/commercial/PremHospital/night/prem17.webp",
        "assets/Allprojects/commercial/PremHospital/night/prem18.webp",
      ],
    },

    {
      name: "coppercanvas",
      videoLink: "https://www.youtube.com/shorts/-70rvppz8fU",
      youtubeLink: "https://www.youtube.com/watch?v=azbJqoT5Wy8",
      instagramLink: "https://www.instagram.com/p/DGSqyO1IT63/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA%3D%3D",
      Projectname:"Copper Canvas",
      metatittles:"Copper-Gold Facade with MetaCassette & MetaFold in Gurugram",
      metadescription:"Metaguise blends MetaCassette perforated panels and MetaFold’s adaptive system for a dynamic residence facade in Gurugram, finished with wood-textured and copper-toned MetaFlutes.",
      images: [
        "assets/Allprojects/residential/CopperCanvas/metacassette/coppercanvas6.webp",
        "assets/Allprojects/residential/CopperCanvas/metacassette/coppercanvas7.webp",
        "assets/Allprojects/residential/CopperCanvas/metacassette/coppercanvas8.webp",
        "assets/Allprojects/residential/CopperCanvas/metacassette/coppercanvas9.webp",
        "assets/Allprojects/residential/CopperCanvas/metacassette/coppercanvas10.webp",
        "assets/Allprojects/residential/CopperCanvas/metafold/coppercanvas2.webp",
        "assets/Allprojects/residential/CopperCanvas/metafold/coppercanvas3.webp",
        "assets/Allprojects/residential/CopperCanvas/metafold/coppercanvas4.webp",
        "assets/Allprojects/residential/CopperCanvas/metafold/coppercanvas5.webp",
      ],
    },

    {
      name: "peachstreet",
      videoLink: "https://www.youtube.com/shorts/rfSF_661G8U",
      youtubeLink: "https://www.youtube.com/watch?v=dsuhIROYiyc",
      instagramLink: "https://www.instagram.com/reel/DC3_Qu0yfbM/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA%3D%3D",
      Projectname:"Peach Street",
      metatittles:"First Triangular MetaCoin Facade in Rose Gold by Metaguise",
      metadescription:"Metaguise debuts its first triangular MetaCoin panels in radiant rose gold, paired with MetaGrey MetaFlutes and solid accents—creating a bold, light-responsive facade for a Gurugram residence by Studio Mathema.",
      images: [
        "assets/Allprojects/residential/PeachStreet/metacoin/peachstreet2.webp",
        "assets/Allprojects/residential/PeachStreet/metacoin/peachstreet3.webp",
        "assets/Allprojects/residential/PeachStreet/metacoin/peachstreet4.webp",
        "assets/Allprojects/residential/PeachStreet/metacoin/peachstreet7.webp",
        "assets/Allprojects/residential/PeachStreet/metacoin/peachstreet9.webp",
        "assets/Allprojects/residential/PeachStreet/metacoin/peachstreet10.webp",
        "assets/Allprojects/residential/PeachStreet/metalouver/peachstreet5.webp",
        "assets/Allprojects/residential/PeachStreet/metalouver/peachstreet6.webp",
        "assets/Allprojects/residential/PeachStreet/metalouver/peachstreet8.webp",
       
      ],
    },

    {
      name: "linearretreat",
      videoLink: "https://www.youtube.com/shorts/L6R_17NCRcY",
      youtubeLink: "https://www.youtube.com/watch?v=rXSQRmGuXnw",
      instagramLink: "https://www.instagram.com/p/C-nDsgvyP1z/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA%3D%3D",
      Projectname:"Linear Retreat",
      metatittles:"Sustainable Facade with MetaLouvers & MetaPlank in MetaWood Finish",
      metadescription:"Designed by Cityspace’82 Architects, this cinematic facade features MetaWood-finish MetaLouvers and MetaPlank underceilings—merging sustainability with modern elegance in a bold architectural statement by Metaguise.",
      images: [
      
        "assets/Allprojects/residential/LinearRetreat/metaflute/linearretreat2.webp",
        "assets/Allprojects/residential/LinearRetreat/metaflute/linearretreat3.webp",
        "assets/Allprojects/residential/LinearRetreat/metaflute/linearretreat4.webp",
        "assets/Allprojects/residential/LinearRetreat/metaflute/linearretreat5.webp",
        "assets/Allprojects/residential/LinearRetreat/metaflute/linearretreat6.webp",
        "assets/Allprojects/residential/LinearRetreat/metaflute/linearretreat7.webp",
        "assets/Allprojects/residential/LinearRetreat/metaflute/linearretreat8.webp",
        "assets/Allprojects/residential/LinearRetreat/metaflute/linearretreat9.webp",
        "assets/Allprojects/residential/LinearRetreat/metalouver/linearretreat8.webp",
        "assets/Allprojects/residential/LinearRetreat/metalouver/linearretreat10.webp",
        "assets/Allprojects/residential/LinearRetreat/metalouver/linearretreat11.webp",
        "assets/Allprojects/residential/LinearRetreat/metalouver/linearretreat12.webp",
      ],
    },
  ];

export const SingleProductDetail = [
    {
      name: "MetaCoin",
      videoLink: "https://www.youtube.com/watch?v=7tqxeMlIMPI",
      youtubeLink: "https://www.youtube.com/watch?v=d_0Ie4xGsKQ",
      instagramLink: "https://www.instagram.com/p/C1HSCBGSJyU/",
      Productname:"MetaCoin",
      metatittles:"MetaCoin | Customizable Metal Coin Facade System ",
      metadescription:"Design a parametric coin facade that's truly yours. Customize shape, size, colour and pattern for standout architecture",
      description:
        "A revolutionary parametric facade system designed to elevate architectural aesthetics. Crafted with premium metals and enhanced with MetaSurface finishes, MetaCoin offers unparalleled customizability, from the shape and size of each element to its color and arrangement. Its dynamic design reacts to light and perspective, creating ever-changing visual effects. Ideal for contemporary and iconic structures, MetaCoin transforms facades into captivating works of art that are sustainable, durable, and visually stunning​​.",
      images: [
        "assets/Allproducts/MetaParametric/metacoin/metacoin1.webp",
        "assets/Allproducts/MetaParametric/metacoin/metacoin2.webp",
        "assets/Allproducts/MetaParametric/metacoin/metacoin3.webp",
        "assets/Allproducts/MetaParametric/metacoin/metacoin4.webp",
        "assets/Allproducts/MetaParametric/metacoin/metacoin5.webp",
        "assets/Allproducts/MetaParametric/metacoin/metacoin6.webp",
        "assets/Allproducts/MetaParametric/metacoin/metacoin7.webp",
        "assets/Allproducts/MetaParametric/metacoin/metacoin8.webp",
        "assets/Allproducts/MetaParametric/metacoin/metacoin9.webp",
        "assets/Allproducts/MetaParametric/metacoin/metacoin10.webp",
        "assets/Allproducts/MetaParametric/metacoin/metacoin11.webp",
        "assets/Allproducts/MetaParametric/metacoin/metacoin12.webp",
        "assets/Allproducts/MetaParametric/metacoin/metacoin13.webp",
        "assets/Allproducts/MetaParametric/metacoin/metacoin14.webp",
        "assets/Allproducts/MetaParametric/metacoin/metacoin15.webp",
        "assets/Allproducts/MetaParametric/metacoin/metacoin16.webp",
        "assets/Allproducts/MetaParametric/metacoin/metacoin17.webp",
        "assets/Allproducts/MetaParametric/metacoin/metacoin18.webp",
        "assets/Allproducts/MetaParametric/metacoin/metacoin19.webp",
        "assets/Allproducts/MetaParametric/metacoin/metacoin20.webp",
        "assets/Allproducts/MetaParametric/metacoin/metacoin21.webp",
        "assets/Allproducts/MetaParametric/metacoin/metacoin22.webp",
        "assets/Allproducts/MetaParametric/metacoin/metacoin23.webp",
        "assets/Allproducts/MetaParametric/metacoin/metacoin24.webp",
        "assets/Allproducts/MetaParametric/metacoin/metacoin25.webp",
        "assets/Allproducts/MetaParametric/metacoin/metacoin26.webp",
        "assets/Allproducts/MetaParametric/metacoin/metacoin27.webp",
        "assets/Allproducts/MetaParametric/metacoin/metacoin28.webp",
      ],
    },
    {
      name: "MetaSequin",
      videoLink: "https://www.youtube.com/shorts/pOor40hc4Zg",
      youtubeLink: "https://www.youtube.com/shorts/Z_o_1Y3Cnpc",
      instagramLink: "https://www.instagram.com/p/DDMsrWUykg_/?img_index=1",
      Productname:"MetaSequin",
      metatittles:"MetaSequin | Bespoke Parametric Metal Sequin Facades ",
      metadescription:"Create dynamic elevations with our metal sequin facade panels, customizable in shape, size, colour and patterns. ",
      description:
        "Elevate your structure with MetaShingles, a system of overlapping metal panels designed to create dynamic textures and visual depth. Fully customizable in shape, size, color and finish, MetaShingles offer endless design possibilities to suit any architectural vision. These shingles bring a contemporary flair to facades while ensuring durability and weather resistance. Perfect for residential and commercial projects, MetaShingles strike a balance between aesthetic appeal and robust functionality, allowing you to tailor your facade to your unique style​.",
      images: [
        "assets/Allproducts/MetaParametric/metasequin/metasequin2.webp",
        "assets/Allproducts/MetaParametric/metasequin/metasequin3.webp",
        "assets/Allproducts/MetaParametric/metasequin/metasequin4.webp",
        "assets/Allproducts/MetaParametric/metasequin/metasequin5.webp",
        "assets/Allproducts/MetaParametric/metasequin/metasequin6.webp",
        "assets/Allproducts/MetaParametric/metasequin/metasequin7.webp",
        "assets/Allproducts/MetaParametric/metasequin/metasequin8.webp",
        "assets/Allproducts/MetaParametric/metasequin/metasequin9.webp",
        "assets/Allproducts/MetaParametric/metasequin/metasequin10.webp",
        "assets/Allproducts/MetaParametric/metasequin/metasequin11.webp",
        "assets/Allproducts/MetaParametric/metasequin/metasequin12.webp",
        "assets/Allproducts/MetaParametric/metasequin/metasequin13.webp",
        "assets/Allproducts/MetaParametric/metasequin/metasequin14.webp",
        "assets/Allproducts/MetaParametric/metasequin/metasequin15.webp",
        "assets/Allproducts/MetaParametric/metasequin/metasequin16.webp",
        "assets/Allproducts/MetaParametric/metasequin/metasequin17.webp",
        "assets/Allproducts/MetaParametric/metasequin/metasequin18.webp",
        "assets/Allproducts/MetaParametric/metasequin/metasequin19.webp",
        "assets/Allproducts/MetaParametric/metasequin/metasequin20.webp",
        "assets/Allproducts/MetaParametric/metasequin/metasequin22.webp",
        "assets/Allproducts/MetaParametric/metasequin/metasequin23.webp",
        "assets/Allproducts/MetaParametric/metasequin/metasequin24.webp",
      ],
    },
    {
      name: "MetaPyramid",
      videoLink: "https://www.youtube.com/watch?v=bsZgLDDyA3A",
      youtubeLink: "https://www.youtube.com/shorts/nkCT_bRGOdQ",
      instagramLink: "https://www.instagram.com/p/C6VouzvSSN6/?img_index=1",
      Productname:"MetaPyramids",
      metatittles:"MetaPyramids | 3D Metal Facade Panels for Elevations",
      metadescription:"MetaPyramids is a 3D metal facade system inspired by the Egyptian Pyramids, adding depth, shadow, and structure to elevations.",
      description:
        "A tribute to ancient architectural wonders, MetaPyramid captures the essence of the pyramids with its geometric precision. Featuring 3D perforations that play with light and shadow, these facades create dynamic visual patterns that change throughout the day. Ideal for bold and iconic designs, MetaPyramid adds a layer of intrigue and timeless sophistication to modern architecture​.",
      images: [
        "assets/Allproducts/MetaForm/metapyramid/metapyramid1.webp",
        "assets/Allproducts/MetaForm/metapyramid/metapyramid2.webp",
        "assets/Allproducts/MetaForm/metapyramid/metapyramid3.webp",
        "assets/Allproducts/MetaForm/metapyramid/metapyramid4.webp",
        "assets/Allproducts/MetaForm/metapyramid/metapyramid5.webp",
        "assets/Allproducts/MetaForm/metapyramid/metapyramid6.webp",
        "assets/Allproducts/MetaForm/metapyramid/metapyramid7.webp",
        "assets/Allproducts/MetaForm/metapyramid/metapyramid8.webp",
        "assets/Allproducts/MetaForm/metapyramid/metapyramid9.webp",
        "assets/Allproducts/MetaForm/metapyramid/metapyramid10.webp",
        "assets/Allproducts/MetaForm/metapyramid/metapyramid11.webp",
        "assets/Allproducts/MetaForm/metapyramid/metapyramid12.webp",
        "assets/Allproducts/MetaForm/metapyramid/metapyramid14.webp",
        "assets/Allproducts/MetaForm/metapyramid/metapyramid15.webp",
      ],
    },
    {
      name: "MetaShingle",
      videoLink: "https://www.youtube.com/shorts/QqrYA55JYHE",
      youtubeLink: "https://www.youtube.com/shorts/x5Gy-hhy9xI",
      instagramLink: "https://www.instagram.com/p/C7BWFlryZs4/",
      Productname:"MetaShingles",
      metatittles:"MetaShingles | Overlapping Metal Facade System",
      metadescription:"MetaShingles is a metal facade system with interlocking, overlapping panels that add texture and create depth in elevations.",
      description:
        "Elevate your structure with MetaShingles, a system of overlapping metal panels designed to create dynamic textures and visual depth. These shingles bring a contemporary flair to facades while ensuring durability and weather resistance. Perfect for residential and commercial projects, MetaShingles strike a balance between aesthetic appeal and robust functionality​.",
      images: [
        "assets/Allproducts/MetaForm/metashingle/metashingle1.webp",
        "assets/Allproducts/MetaForm/metashingle/metashingle2.webp",
        "assets/Allproducts/MetaForm/metashingle/metashingle3.webp",
        "assets/Allproducts/MetaForm/metashingle/metashingle4.webp",
        "assets/Allproducts/MetaForm/metashingle/metashingle5.webp",
        "assets/Allproducts/MetaForm/metashingle/metashingle6.webp",
        "assets/Allproducts/MetaForm/metashingle/metashingle7.webp",
        "assets/Allproducts/MetaForm/metashingle/metashingle8.webp",
        "assets/Allproducts/MetaForm/metashingle/metashingle9.webp",
        "assets/Allproducts/MetaForm/metashingle/metashingle10.webp",
        "assets/Allproducts/MetaForm/metashingle/metashingle11.webp",
        "assets/Allproducts/MetaForm/metashingle/metashingle12.webp",
      ],
    },
    {
      name: "CascadingKeys",
      videoLink: "https://www.youtube.com/shorts/JF9Y-sPUlEM",
      youtubeLink: "https://www.youtube.com/watch?v=WddafN0E69M",
      instagramLink: "https://www.instagram.com/p/C6TeqtOSgsn/?img_index=1",
      Productname:"Cascading Keys",
      metatittles:"Cascading Keys | Parametric Facade Metarials with Fluid Design",
      metadescription:"A parametric metal facade product inspired by chimes, Cascading Keys features keys that flow with light, depth, and movement.",
      description:
        "An architectural ode to natural harmony, Cascading Keys incorporates aluminum and stainless steel in flowing forms that mimic the rhythm of nature. These facades resemble the gentle hum of wind chimes or the flutter of butterfly wings, captivating viewers with their elegance. Ideal for tranquil, design-forward spaces, Cascading Keys creates a seamless blend of beauty and function​.",
      images: [
        "assets/Allproducts/MetaForm/CascadingKeys/caskey2.webp",
        "assets/Allproducts/MetaForm/CascadingKeys/caskey3.webp",
        "assets/Allproducts/MetaForm/CascadingKeys/caskey4.webp",
        "assets/Allproducts/MetaForm/CascadingKeys/caskey5.webp",
        "assets/Allproducts/MetaForm/CascadingKeys/caskey6.webp",
      ],
    },
    {
      name: "MetaCassette",
      videoLink: "https://www.youtube.com/shorts/oOiyN07PwQE",
      youtubeLink: "https://www.youtube.com/watch?v=AtzVGYkpOxs",
      instagramLink: "https://www.instagram.com/p/C6svvLSSWU2/?img_index=1",
      Productname:"MetaCassette",
      metatittles:"MetaCassette | Versatile Metal Facade Cladding Solutions",
      metadescription:"MetaCassette is a metal facade cladding system with solid, perforated, and grooved panels.",
      description:
        "A versatile facade solution, MetaCassette offers three distinct panel types—grooved, perforated, and solid—each designed to bring unique aesthetic and functional qualities to architectural projects. Together, these options empower architects and designers to craft facades that are not only visually captivating but also tailored to specific functional requirements​​.",
      images: [
        "assets/Allproducts/MetaForm/metacassette/metacassette1.webp",
        "assets/Allproducts/MetaForm/metacassette/metacassette2.webp",
        "assets/Allproducts/MetaForm/metacassette/metacassette3.webp",
        "assets/Allproducts/MetaForm/metacassette/metacassette4.webp",
        "assets/Allproducts/MetaForm/metacassette/metacassette5.webp",
        "assets/Allproducts/MetaForm/metacassette/metacassette6.webp",
        "assets/Allproducts/MetaForm/metacassette/metacassette7.webp",
        "assets/Allproducts/MetaForm/metacassette/metacassette8.webp",
        "assets/Allproducts/MetaForm/metacassette/metacassette9.webp",
        "assets/Allproducts/MetaForm/metacassette/metacassette10.webp",
        "assets/Allproducts/MetaForm/metacassette/metacassette11.webp",
        "assets/Allproducts/MetaForm/metacassette/metacassette12.webp",
        "assets/Allproducts/MetaForm/metacassette/metacassette13.webp",
        "assets/Allproducts/MetaForm/metacassette/metacassette14.webp",
        "assets/Allproducts/MetaForm/metacassette/metacassette15.webp",
        "assets/Allproducts/MetaForm/metacassette/metacassette16.webp",
        "assets/Allproducts/MetaForm/metacassette/metacassette17.webp",
        "assets/Allproducts/MetaForm/metacassette/metacassette19.webp",
        "assets/Allproducts/MetaForm/metacassette/metacassette20.webp",
        "assets/Allproducts/MetaForm/metacassette/metacassette21.webp",
      ],
    },
    {
      name: "MetaFlute",
      videoLink: "https://www.youtube.com/shorts/xQ9DUg325RM",
      youtubeLink: "https://www.youtube.com/shorts/L6R_17NCRcY",
      instagramLink: "https://www.instagram.com/p/C52rD3KyYBU/?img_index=4",
      Productname:"MetaFlute",
      metatittles:"MetaFlute | Durable Metal Facade Cladding System",
      metadescription:"MetaFlute is a metal facade cladding system with bold lines and linear profiles adding depth and texture.",
      description:
        "A sleek solution for architectural facades, MetaFlute combines lightweight yet durable metals to create rhythmic, vertical lines that enhance any structure. These extrusions shield buildings from harsh weather while adding a touch of modern sophistication. MetaFlute is ideal for projects requiring elegance, resilience, and minimal maintenance​​.",
      images: [
        "assets/Allproducts/MetaForm/metaflute/metaflute1.webp",
        "assets/Allproducts/MetaForm/metaflute/metaflute2.webp",
        "assets/Allproducts/MetaForm/metaflute/metaflute3.webp",
        "assets/Allproducts/MetaForm/metaflute/metaflute4.webp",
        "assets/Allproducts/MetaForm/metaflute/metaflute5.webp",
        "assets/Allproducts/MetaForm/metaflute/metaflute6.webp",
        "assets/Allproducts/MetaForm/metaflute/metaflute7.webp",
        "assets/Allproducts/MetaForm/metaflute/metaflute8.webp",
        "assets/Allproducts/MetaForm/metaflute/metaflute9.webp",
        "assets/Allproducts/MetaForm/metaflute/metaflute10.webp",
        "assets/Allproducts/MetaForm/metaflute/metaflute11.webp",
        "assets/Allproducts/MetaForm/metaflute/metaflute13.webp",
        "assets/Allproducts/MetaForm/metaflute/metaflute14.webp",
        "assets/Allproducts/MetaForm/metaflute/metaflute15.webp",
        "assets/Allproducts/MetaForm/metaflute/metaflute16.webp",
      ],
    },
    {
      name: "MetaFold",
      videoLink: "https://www.youtube.com/shorts/bztzIQXiq30",
      youtubeLink: "https://www.youtube.com/watch?v=Zyfa8ufnC0s",
      instagramLink: "https://www.instagram.com/p/DGSqyO1IT63/?img_index=1",
      Productname:"MetaFold",
      metatittles:"MetaFold | Folding Metal Facade System ",
      metadescription:"MetaFold is a functional metal facade system with folding panels designed for flexibility, ventilation, and smooth indoor-outdoor transitions.",
      description:
        "MetaFold transforms spaces with its innovative folding panels, seamlessly connecting indoor and outdoor environments. Featuring multi-faceted panes crafted from premium metals, it creates dynamic openings that enhance airflow and natural light. Perfect for modern homes and commercial spaces, MetaFold embodies functional luxury and design flexibility​.",
      images: [
        "assets/Allproducts/MetaFunction/metafold/metafold2.webp",
        "assets/Allproducts/MetaFunction/metafold/metafold3.webp",
        "assets/Allproducts/MetaFunction/metafold/metafold4.webp",
        "assets/Allproducts/MetaFunction/metafold/metafold5.webp",
        "assets/Allproducts/MetaFunction/metafold/metafold6.webp",
      ],
    },
    {
      name: "MetaFin",
      videoLink: "https://www.youtube.com/watch?v=HVRcBLgKIeA",
      youtubeLink: "https://www.youtube.com/watch?v=HVRcBLgKIeA",
      instagramLink: "https://www.instagram.com/p/C0ok5qASjfQ/?img_index=3",
      Productname:"MetaFin",
      metatittles:"MetaFin | Parametric Facade in Metal with Fluid Curves",
      metadescription:"MetaFin is a parametric facade system with fluid, curving forms - ideal for creating soft, sculptural surfaces in architecture.",
      description:
        "Showcasing the perfect blend of fluidity and structure, MetaFin features parametric designs crafted from lightweight metals. With its smooth, organic curves and ability to reflect and play with light, MetaFin creates a stunning visual narrative. This facade system is ideal for projects that demand sustainable, artistic, and high-performance solutions​​.",
      images: [
        "assets/Allproducts/MetaParametric/metafin/metafin2.webp",
        "assets/Allproducts/MetaParametric/metafin/metafin1.webp",
        "assets/Allproducts/MetaParametric/metafin/metafin3.webp",
        "assets/Allproducts/MetaParametric/metafin/metafin4.webp",
        "assets/Allproducts/MetaParametric/metafin/metafin5.webp",
        "assets/Allproducts/MetaParametric/metafin/metafin6.webp",
      ],
    },
    {
      name: "MetaHydra",
      videoLink: "https://www.youtube.com/watch?v=WZQaImzTZiE",
      youtubeLink: "https://www.youtube.com/watch?v=WZQaImzTZiE",
      instagramLink: "https://www.instagram.com/p/Cxp-xPFv7RR/?img_index=1",
      Productname:"MetaHydra",
      metatittles:"MetaHydra | Sculpted Copper Metal Facade Cladding",
      metadescription:"MetaHydra is a metal facade product made with copper that evolves over time, revealing rich tones and organic textures.",
      description:
        "MetaHydra brings together the beauty of copper and the resilience of parametric design. Its unique panels mimic the fluidity of water, transforming facades into living works of art. The pure copper material ages gracefully, developing a rich patina that adds character over time, making it a luxurious choice for iconic architectural projects​.",
      images: [
        "assets/Allproducts/MetaParametric/metahydra/metahydra1.webp",
        "assets/Allproducts/MetaParametric/metahydra/metahydra2.webp",
        "assets/Allproducts/MetaParametric/metahydra/metahydra3.webp",
        "assets/Allproducts/MetaParametric/metahydra/cover/metahydra.webp",
      ],
    },
    {
      name: "MetaLouver",
      videoLink: "https://www.youtube.com/shorts/HVUP7YV59PM",
      youtubeLink: "https://www.youtube.com/shorts/EerdVK4Ax9M",
      instagramLink: "https://www.instagram.com/p/C_m5fBNhNuX/?img_index=1",
      Productname:"MetaLouver",
      metatittles:"MetaLouver | Metal Louver Facade System for Elevations",
      metadescription:"MetaLouver is a metal louver facade system designed for light, airflow, and shade - a durable, high-value solution for any project.",
      description:
        "MetaLouver offers a harmonious balance of form and functionality. Its adjustable, angular metal panels allow for optimal control of light and ventilation while shielding interiors from rain and harsh sunlight. Whether used for facades or shading systems, MetaLouver adds a distinctive architectural rhythm to buildings​​.",
      images: [
        "assets/Allproducts/MetaForm/metalouver/metalouver1.webp",
        "assets/Allproducts/MetaForm/metalouver/metalouver9.webp",
        "assets/Allproducts/MetaForm/metalouver/metalouver4.webp",
        "assets/Allproducts/MetaForm/metalouver/metalouver5.webp",
        "assets/Allproducts/MetaForm/metalouver/metalouver6.webp",
        "assets/Allproducts/MetaForm/metalouver/metalouver7.webp",
        "assets/Allproducts/MetaForm/metalouver/metalouver8.webp",
      ],
    },
    {
      name: "MetaPlank",
      videoLink: "https://www.youtube.com/shorts/gSDtx-QkGhw",
      youtubeLink: "https://www.youtube.com/shorts/gSDtx-QkGhw",
      instagramLink: "https://www.instagram.com/p/C-nDsgvyP1z/?img_index=1",
      Productname:"MetaPlank",
      metatittles:"MetaPlank | Sleek Metal Facade Cladding Material",
      metadescription:"MetaPlank is a metal facade cladding system with sleek extrusions - a low-maintenance material built for modern design.",
      description:
        "MetaPlank combines the natural appeal of wood with the resilience of metal, offering a durable, weather-resistant cladding solution. Its earthy tones and realistic textures bring warmth to any structure, while its maintenance-free properties make it a sustainable and cost-effective alternative to traditional wood​​.",
      images: [
        "assets/Allproducts/MetaForm/metaplank/metaplank1.webp",
        "assets/Allproducts/MetaForm/metaplank/metaplank3.webp",
        "assets/Allproducts/MetaForm/metaplank/metaplank4.webp",
        "assets/Allproducts/MetaForm/metaplank/metaplank5.webp",
        "assets/Allproducts/MetaForm/metaplank/metaplank6.webp",
      ],
    },
    {
      name: "MetaGate",
      videoLink: "https://www.youtube.com/shorts/2hPBUOLftgA",
      youtubeLink: "https://www.youtube.com/watch?v=3Fm3gxL0lPg",
      instagramLink: "https://www.instagram.com/p/C55XSGxSSsh/?img_index=3",
      Productname:"MetaGate",
      metatittles:"MetaGate | Architectural Metal Facade Gates by Metaguise",
      metadescription:"MetaGate is a premium metal gate system, designed with our facade cladding solutions for seamless architecture.",
      description:
        "A stylish and functional entry solution, MetaGate is crafted from lightweight aluminum and can be customized with manual or automated sliding, swinging, or folding mechanisms. Offering sleek aesthetics and robust performance, it’s the perfect addition to both contemporary and classic architectural designs​.",
      images: [
        "assets/Allproducts/MetaFunction/metagate/metagate1.webp",
        "assets/Allproducts/MetaFunction/metagate/metagate2.webp",
        "assets/Allproducts/MetaFunction/metagate/metagate3.webp",
        "assets/Allproducts/MetaFunction/metagate/metagate5.webp",
        "assets/Allproducts/MetaFunction/metagate/metagate6.webp",
        "assets/Allproducts/MetaFunction/metagate/metagate7.webp",
      ],
    },
    {
      name: "MetaSlider",
      videoLink: "https://www.youtube.com/watch?v=bFMkX9cXhPM",
      youtubeLink: "https://www.youtube.com/watch?v=bFMkX9cXhPM",
      instagramLink: "https://www.instagram.com/reel/DAFyTxQSMWu/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==",
      Productname:"MetaSlider",
      metatittles:"MetaSlider | Sliding Metal Facade System",
      metadescription:"MetaSlider is a metal facade system with sliding panels that enhance design flexibility, airflow, and natural light control.",
      description:
        " MetaSlider integrates sliding facade technology to create dynamic architectural expressions. Whether open to embrace natural light or closed for privacy, it adapts seamlessly to climatic and aesthetic needs. MetaSlider’s versatility makes it a go-to solution for modern facades that balance functionality and design​.",
      images: [
        "assets/Allproducts/MetaFunction/metaslider/metaslider1.webp",
        "assets/Allproducts/MetaFunction/metaslider/metaslider3.webp",
        "assets/Allproducts/MetaFunction/metaslider/cover/metaslider.webp",
      ],
    },
    {
      name: "MetaBlox",
      videoLink: "https://www.youtube.com/watch?v=YzKljcP5S4s",
      youtubeLink: "https://www.youtube.com/watch?v=YzKljcP5S4s",
      instagramLink: "https://www.instagram.com/p/Cr5Xh-INnvp/",
      Productname:"MetaBlox",
      metatittles:"MetaBlox | Shape Changing Metal Facade Cladding System",
      metadescription:"MetaBlox is a modular metal facade cladding system with kinetic blocks that form bold, ever-evolving facades.",
      description:
        "The first-ever shape-changing facade system, MetaBlox combines cutting-edge parametric technology with visually mesmerizing designs. Made from pure aluminum, it offers a unique, customizable facade solution that adapts to light and perspective, making every project a masterpiece​.",
      images: [
        "assets/Allproducts/MetaParametric/metablox/metablox1.webp",
        "assets/Allproducts/MetaParametric/metablox/metablox2.webp",
        "assets/Allproducts/MetaParametric/metablox/cover/metablox.webp",
      ],
    },
    {
      name: "MetaCopper",
      videoLink: "https://www.youtube.com/watch?v=-pLr2ZVXycY",
      youtubeLink: "https://www.youtube.com/watch?v=-pLr2ZVXycY",
      instagramLink: "https://www.instagram.com/p/C39pYFwyFKK/",
      Productname:"MetaCopper",
      metatittles:"MetaCopper | Pure Copper Finish for Facade Cladding",
      metadescription:"MetaCopper is a refined metal facade surface crafted in pure copper for a durable, timeless finish.",
      description:
        "A timeless material reimagined, MetaCopper integrates the warmth and character of copper into modern architectural designs. Its rustic ochre-red hues, combined with its durability and resistance to corrosion, make it a stunning choice for facades that exude both tradition and innovation​.",
      images: [
        "assets/Allproducts/MetaSurface/metacopper/metacopper2.webp",
        "assets/Allproducts/MetaSurface/metacopper/metacopper3.webp",
        "assets/Allproducts/MetaSurface/metacopper/metacopper4.webp",
        "assets/Allproducts/MetaSurface/metacopper/metacopper5.webp",
        "assets/Allproducts/MetaSurface/metacopper/metacopper6.webp",
        "assets/Allproducts/MetaSurface/metacopper/metacopper7.webp",
        "assets/Allproducts/MetaSurface/metacopper/metacopper8.webp",
        "assets/Allproducts/MetaSurface/metacopper/metacopper9.webp",
        "assets/Allproducts/MetaSurface/metacopper/metacopper10.webp",
        "assets/Allproducts/MetaSurface/metacopper/metacopper11.webp",
        "assets/Allproducts/MetaSurface/metacopper/metacopper12.webp",
      ],
    },
    {
      name: "MetaCorten",
      videoLink: "https://www.youtube.com/watch?v=MG0e8RfM-6s",
      youtubeLink: "https://www.youtube.com/watch?v=MG0e8RfM-6s",
      instagramLink: "https://www.instagram.com/p/CgXMjRilJco/",
      Productname:"MetaCorten",
      metatittles:"MetaCorten | Rustic Surface Finish for Facade Cladding",
      metadescription:"MetaCorten is a metal cladding surface with a weathered finish - ideal for durable, rustic-inspired facade cladding.",
      description:
        "Embracing the raw beauty of weathered steel, MetaCorten offers a rich reddish-brown facade that evolves aesthetically over time. Its advanced coating ensures maintenance-free performance, making it a sustainable, modern choice for architectural cladding​.",
      images: [
        "assets/Allproducts/MetaSurface/metacorten/metacorten1.webp",
        "assets/Allproducts/MetaSurface/metacorten/metacorten2.webp",
        "assets/Allproducts/MetaSurface/metacorten/metacorten4.webp",
        "assets/Allproducts/MetaSurface/metacorten/metacorten5.webp",
        "assets/Allproducts/MetaSurface/metacorten/metacorten6.webp",
        "assets/Allproducts/MetaSurface/metacorten/metacorten7.webp",
      ],
    },
    {
      name: "MetaGrey",
      videoLink: "https://www.youtube.com/watch?v=AtzVGYkpOxs",
      youtubeLink: "https://www.youtube.com/watch?v=AtzVGYkpOxs",
      instagramLink: "https://www.instagram.com/p/DCrHdiwyBBL/?img_index=1",
      Productname:"MetaGrey",
      metatittles:"MetaGrey | Grey Metal Facade Surface Finish",
      metadescription:"MetaGrey is a metal facade surface finish for a sleek, industrial look.",
      description:
        "A sophisticated grayscale facade system, MetaGrey blends seamlessly with contemporary architecture. Its subtle tones provide visual balance and versatility, making it ideal for projects that require understated elegance with a modern edge​.",
      images: [
        "assets/Allproducts/MetaSurface/metagrey/metagrey1.webp",
        "assets/Allproducts/MetaSurface/metagrey/metagrey2.webp",
        "assets/Allproducts/MetaSurface/metagrey/metagrey3.webp",
        "assets/Allproducts/MetaSurface/metagrey/metagrey4.webp",
        "assets/Allproducts/MetaSurface/metagrey/metagrey5.webp",
        "assets/Allproducts/MetaSurface/metagrey/metagrey7.webp",
        "assets/Allproducts/MetaSurface/metagrey/metagrey8.webp",
        "assets/Allproducts/MetaSurface/metagrey/metagrey9.webp",
        "assets/Allproducts/MetaSurface/metagrey/metagrey10.webp",
        "assets/Allproducts/MetaSurface/metagrey/metagrey6.webp",
      ],
    },
    {
      name: "MetaPatina",
      videoLink: "https://www.youtube.com/shorts/VP3dKv1dUAw",
      youtubeLink: "https://www.youtube.com/watch?v=zk2w03avHp4",
      instagramLink: "https://www.instagram.com/p/C_FQoMaSB8-/?img_index=1",
      Productname:"MetaPatina",
      metatittles:"MetaPatina | Verdigris Metal Facade Surface Finish",
      metadescription:"MetaPatina is a metal facade surface in aged copper and brass giving a a timeless look with a natural verdigris finish.",
      description:
        " MetaPatina showcases the artistry of oxidized metals, featuring verdigris tints on copper and brass for a luxurious, aged look. With its antimicrobial properties and striking color contrasts, MetaPatina is perfect for interior and exterior applications that demand both beauty and functionality​.",
      images: [
        "assets/Allproducts/MetaSurface/metapatina/metapatina1.webp",
        "assets/Allproducts/MetaSurface/metapatina/metapatina2.webp",
        "assets/Allproducts/MetaSurface/metapatina/metapatina3.webp",
        "assets/Allproducts/MetaSurface/metapatina/metapatina4.webp",
        "assets/Allproducts/MetaSurface/metapatina/metapatina5.webp",
        "assets/Allproducts/MetaSurface/metapatina/metapatina6.webp",
        "assets/Allproducts/MetaSurface/metapatina/metapatina7.webp",
        "assets/Allproducts/MetaSurface/metapatina/metapatina8.webp",
      ],
    },
    {
      name: "MetaWood",
      videoLink: "https://www.youtube.com/shorts/f-mC4kTXhCg",
      youtubeLink: "https://www.youtube.com/shorts/sRjbbaa9cbw",
      instagramLink: "https://www.instagram.com/p/C8_hZvXS2OA/?img_index=1",
      Productname:"MetaWood",
      metatittles:"MetaWood | Wood-Finish Metal Facade Surface ",
      metadescription:"MetaWood is a durable metal facade surface finish with a wooden texture for an organic look.",
      description:
        "A sustainable reimagining of natural wood, MetaWood combines the visual appeal of earthy textures with the strength of metal. Resistant to weather, fire, and termites, it is an environmentally friendly, durable solution for cladding and decorative applications​.",
      images: [
        "assets/Allproducts/MetaSurface/metawood/metawood1.webp",
        "assets/Allproducts/MetaSurface/metawood/metawood2.webp",
        "assets/Allproducts/MetaSurface/metawood/metawood3.webp",
        "assets/Allproducts/MetaSurface/metawood/metawood4.webp",
        "assets/Allproducts/MetaSurface/metawood/metawood5.webp",
        "assets/Allproducts/MetaSurface/metawood/metawood6.webp",
        "assets/Allproducts/MetaSurface/metawood/metawood7.webp",
        "assets/Allproducts/MetaSurface/metawood/metawood8.webp",
        "assets/Allproducts/MetaSurface/metawood/metawood9.webp",
        "assets/Allproducts/MetaSurface/metawood/metawood10.webp",
        "assets/Allproducts/MetaSurface/metawood/metawood11.webp",
      ],
    },
    {
      name: "SolidPanel",
      videoLink: "https://www.youtube.com/shorts/VTGrtakTJgM",
      youtubeLink: "https://www.youtube.com/watch?v=oEKOjT0tAWk",
      instagramLink: "https://www.instagram.com/p/C_kTfLGSf3W/",
      Productname:"Solid Panels",
      metatittles:"Solid Metal Panels | Durable Facade Panels for Architecture",
      metadescription:"Our solid metal facade panels offer strength and style for modern and industrial architecture.",
      description:
        "Durable and versatile, Solid Panels are crafted from premium metals for seamless, modern facades. With exceptional weather resistance and low maintenance, they suit both bold designs and intricate architectural projects. Fully customizable in size, finish, and color, Solid Panels blend strength with aesthetic appeal for a variety of applications.",
      images: [
        "assets/Allproducts/MetaForm/SolidPanel/solidpanel1.webp",
        "assets/Allproducts/MetaForm/SolidPanel/solidpanel2.webp",
        "assets/Allproducts/MetaForm/SolidPanel/solidpanel3.webp",
        "assets/Allproducts/MetaForm/SolidPanel/solidpanel4.webp",
        "assets/Allproducts/MetaForm/SolidPanel/solidpanel5.webp",
        "assets/Allproducts/MetaForm/SolidPanel/solidpanel7.webp",
        "assets/Allproducts/MetaForm/SolidPanel/solidpanel8.webp",
        "assets/Allproducts/MetaForm/SolidPanel/solidpanel9.webp",
      ],
    },
  ];

export const ProductImages = [
    {
      imgPath: "assets/Allproducts/MetaParametric/metacoin/cover/metacoin.webp",
      imgText: "MetaCoin",
      order: 1,
    },
    {
      imgPath:
        "assets/Allproducts/MetaParametric/metasequin/cover/metasequin1.webp",
      imgText: "MetaSequin",
      order: 2,
    },
    {
      imgPath: "assets/Allproducts/MetaForm/metapyramid/cover/metapyramid.webp",
      imgText: "MetaPyramid",
      order: 4,
    },
    {
      imgPath: "assets/Allproducts/MetaForm/metashingle/cover/metashingle.webp",
      imgText: "MetaShingles",
      order: 5,
    },
    {
      imgPath: "assets/Allproducts/MetaForm/CascadingKeys/cover/caskey1.webp",
      imgText: "Cascading Keys",
      order: 6,
    },
    {
      imgPath:
        "assets/Allproducts/MetaForm/metacassette/cover/metacassette.webp",
      imgText: "MetaCassette",
      order: 3,
    },
    {
      imgPath: "assets/Allproducts/MetaForm/metaflute/cover/metaflute.webp",
      imgText: "MetaFlute",
      order: 8,
    },
    {
      imgPath: "assets/Allproducts/MetaFunction/metafold/cover/metafold1.webp",
      imgText: "MetaFold",
      order: 10,
    },
    {
      imgPath: "assets/Allproducts/MetaParametric/metafin/cover/metafin.webp",
      imgText: "MetaFin",
      order: 9,
    },
    {
      imgPath:
        "assets/Allproducts/MetaParametric/metahydra/cover/metahydra.webp",
      imgText: "MetaHydra",
      order: 19,
    },
    {
      imgPath: "assets/Allproducts/MetaForm/metalouver/cover/metalouver.webp",
      imgText: "MetaLouver",
      order: 21,
    },
    {
      imgPath: "assets/Allproducts/MetaForm/metaplank/cover/metaplank.webp",
      imgText: "MetaPlank",
      order: 12,
    },
    {
      imgPath: "assets/Allproducts/MetaFunction/metagate/cover/metagate4.webp",
      imgText: "MetaGate",
      order: 13,
    },
    {
      imgPath:
        "assets/Allproducts/MetaFunction/metaslider/cover/metaslider.webp",
      imgText: "MetaSlider",
      order: 11,
    },
    {
      imgPath: "assets/Allproducts/MetaParametric/metablox/cover/metablox.webp",
      imgText: "MetaBlox",
      order: 20,
    },
    {
      imgPath:
        "assets/Allproducts/MetaSurface/metacopper/cover/metacopper1.webp",
      imgText: "MetaCopper",
      order: 14,
    },
    {
      imgPath:
        "assets/Allproducts/MetaSurface/metacorten/cover/metacorten3.webp",
      imgText: "MetaCorten",
      order: 16,
    },
    {
      imgPath: "assets/Allproducts/MetaSurface/metagrey/cover/metagrey.webp",
      imgText: "MetaGrey",
      order: 17,
    },
    {
      imgPath: "assets/Allproducts/MetaSurface/metapatina/cover/metapatina.webp",
      imgText: "MetaPatina",
      order: 15,
    },
    {
      imgPath: "assets/Allproducts/MetaSurface/metawood/cover/metawood.webp",
      imgText: "MetaWood",
      order: 18,
    },
    {
      imgPath: "assets/Allproducts/MetaForm/SolidPanel/cover/solidpanel.webp",
      imgText: "SolidPanel",
      order: 7,
    },
  ];

export const ProjectImages = [
    { imgPath: "assets/Allprojects/commercial/Ardete/cover/ardete5.webp",order: 1 },
    { imgPath: "assets/Allprojects/commercial/Tanishq/cover/tanishq11.webp",order: 2 },
    { imgPath: "assets/Allprojects/residential/A13-43/cover/a13-431.webp",order: 7 },
    { imgPath: "assets/Allprojects/residential/D5-3/cover/ankit1.webp",order: 4 },
    { imgPath: "assets/Allprojects/commercial/Gowri/cover/gowri2.webp",order: 5 },
    { imgPath: "assets/Allprojects/commercial/Deepak/cover/deepak1.webp",order: 14 },
    { imgPath: "assets/Allprojects/residential/A19/cover/a192.webp",order: 18 },
    { imgPath: "assets/Allprojects/residential/A18/cover/a181.webp", order: 11 },
    { imgPath: "assets/Allprojects/residential/MVA-DL1/cover/varun5.webp" },
    { imgPath: "assets/Allprojects/commercial/Metaland/cover/metaland6.webp",order: 20 },
    { imgPath: "assets/Allprojects/commercial/Micasa/cover/micasa1.webp",order: 21 },
    { imgPath: "assets/Allprojects/commercial/yashika/cover/yashika1.webp" },
    { imgPath: "assets/Allprojects/residential/C76/cover/c7615.webp",order: 22 },
    { imgPath: "assets/Allprojects/residential/5107/cover/51071.webp",order: 13 },
    { imgPath: "assets/Allprojects/residential/A297/cover/a2971.webp",order: 16 },
    // { imgPath: "assets/Allprojects/residential/linearretreat/cover/linearretreat1.webp" },
    { imgPath: "assets/Allprojects/residential/C34-573/cover/kapil1.webp",order: 23  },
    { imgPath: "assets/Allprojects/residential/DLF4/cover/dlf44.webp",order: 24 },
    { imgPath: "assets/Allprojects/residential/CS5-MA/cover/vishal1.webp",order: 15 },
    { imgPath: "assets/Allprojects/residential/Espace/cover/espace1.webp",order: 6 },
    { imgPath: "assets/Allprojects/residential/MA-543/cover/manoj1.webp", order: 25 },
    { imgPath: "assets/Allprojects/residential/PeachStreet/cover/peachstreet1.webp",},
    { imgPath: "assets/Allprojects/residential/CopperCanvas/cover/coppercanvas1.webp",},
    { imgPath: "assets/Allprojects/residential/LinearRetreat/cover/linearretreat1.webp",},
    { imgPath: "assets/Allprojects/residential/Q62-RA/cover/tejbir1.webp",order: 8 },
    { imgPath: "assets/Allprojects/residential/z1012/cover/z10121.webp",order: 26 },
    { imgPath: "assets/Allprojects/residential/Luxe/cover/luxe16.webp",order: 3 },
    { imgPath: "assets/Allprojects/commercial/E10/cover/e105.webp",order: 17 },
    { imgPath: "assets/Allprojects/commercial/Painflame/cover/15.webp", order: 12 },
    { imgPath: "assets/Allprojects/commercial/PremHospital/cover/prem1.webp",order: 10 },
  ];

  // In your constants.js (or directly in the component)
export const products = [
  {
    id: 1,
    name: "Metacoin",
    
    slug: "metacoin"
  },
  {
    id: 2,
    name: "Metasequin",
    
    slug: "metasequin"
  },
  {
    id: 3,
    name: "Cascading Keys",
    
    slug: "cascading-keys"
  },
  {
    id: 4,
    name: "Metashingles",
    
    slug: "metashingles"
  },
];

export const sortedProductImages = ProductImages.sort((a, b) => a.order - b.order);
export const sortedProjects = ProjectImages.sort((a, b) => a.order - b.order); // Ascending order
